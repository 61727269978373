import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router";
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonToggle,
} from "@ionic/react";
import {
  calendarOutline,
  hammer,
  moonOutline,
  help,
  informationCircleOutline,
  logIn,
  logOut,
  mapOutline,
  peopleOutline,
  person,
  personAdd,
  homeOutline,
  listOutline,
  notificationsOutline,
  chatbubblesOutline,
} from "ionicons/icons";

//import { setDarkMode } from "../data/user/user.actions";
import "./Menu.css";

const routes = {
  AppPages: [
    { title: "דף הבית", path: "/home", icon: homeOutline },
    { title: "פעולות", path: "/operations", icon: listOutline },
    {
      title: "התראות",
      path: "/notifications",
      icon: notificationsOutline,
    },
    { title: "הודעות", path: "/chats", icon: chatbubblesOutline },
  ],
  AccountPages: [
    { title: "החשבון שלי", path: "/profile", icon: person },

    { title: "התנתק", path: "/logout", icon: logOut },
  ],
  HelpPages: [{ title: "תפעול האפליקציה", path: "/support", icon: help }],
  loggedOutPages: [
    { title: "התחברות", path: "/login", icon: logIn },
    { title: "תפעול האפליקציה", path: "/support", icon: help },
  ],
};

interface Pages {
  title: string;
  path: string;
  icon: string;
  routerDirection?: string;
}

interface MenuProps extends RouteComponentProps {}

const Menu: React.FC<MenuProps> = ({ history }) => {
  const location = useLocation();

  function renderlistItems(list: Pages[]) {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle slot="start" key={p.title} auto-hide="false">
          <IonItem
            detail={false}
            routerLink={p.path}
            routerDirection="none"
            className={
              location.pathname.startsWith(p.path) ? "selected" : undefined
            }
          >
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
  });

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth });
  }
  useEffect(() => {
    window.addEventListener("resize", changeWindowSize);

    return () => {
      window.removeEventListener("resize", changeWindowSize);
    };
  }, []);

  return (
    <IonMenu
      side={windowSize.width > 992 ? "start" : "end"}
      type="overlay"
      //disabled={true}
      contentId="main"
    >
      <IonContent forceOverscroll={false}>
        <IonList className="ion-padding-top ion-margin-top" lines="none">
          <IonListHeader>קישורים</IonListHeader>
          {renderlistItems(routes.AppPages)}
        </IonList>
        <IonList lines="none">
          <IonListHeader>החשבון שלי</IonListHeader>

          {renderlistItems(routes.AccountPages)}

          <IonItem>
            <IonIcon slot="start" icon={moonOutline}></IonIcon>
            <IonLabel>מצב חשוך</IonLabel>
            <IonToggle
              checked={false} //darkmode
              //onClick={() => setDarkMode(!darkMode)}
            />
          </IonItem>
        </IonList>
        <IonList lines="none">
          <IonListHeader>עזרה</IonListHeader>
          {renderlistItems(routes.HelpPages)}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default withRouter(
  Menu
); /* <{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin,
    menuEnabled: state.data.menuEnabled,
  }),
  mapDispatchToProps: {
    //setDarkMode,
  },
  component: withRouter(Menu),
}); */
