import React, { useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonImg,
  IonText,
  IonSearchbar,
  IonItemDivider,
} from "@ionic/react";
import "./TabOperations.css";
import saftey from "../assets/worker.svg";
import workers from "../assets/engineer.svg";
import orders from "../assets/fast-delivery.svg";
import calendar from "../assets/calendar.svg";
import steps from "../assets/timeline.svg";
import { pin, wifi, wine, warning, walk } from "ionicons/icons";
const TabOperations: React.FC = () => {
  const [searchText, setSearchText] = useState("");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton autoHide={false} />
          </IonButtons>
          <IonTitle color="primary">פעולות</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        style={{ background: "#f1f1f1" }}
        className="ion-text-center "
      >
        <IonSearchbar
          className="ion-padding-top ion-padding-bottom "
          style={{ borderBottom: "1px solid #f5f5f5" }}
          placeholder="שם הפעולה..."
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value!)}
        ></IonSearchbar>

        <IonGrid>
          <IonRow>
            <IonCol
              className="ion-no-margin ion-no-padding"
              size="12"
              sizeXs="6"
              sizeSm="6"
              sizeMd="4"
              sizeLg="3"
              sizeXl="3"
            >
              <IonCard
                style={{ cursor: "pointer" }}
                onClick={() => {
                  alert("cleicked");
                }}
              >
                <IonCardHeader className="ion-justify-content-center ion-align-items-center">
                  <IonImg
                    src={saftey}
                    style={{ maxWidth: 80, maxHeight: 80, margin: "0 auto" }}
                  ></IonImg>

                  <IonCardTitle>בטיחות באתר</IonCardTitle>
                </IonCardHeader>

                {/* <IonCardContent>edit</IonCardContent> */}
              </IonCard>
            </IonCol>
            <IonCol
              className="ion-no-margin ion-no-padding"
              size="12"
              sizeXs="6"
              sizeSm="6"
              sizeMd="4"
              sizeLg="3"
              sizeXl="3"
            >
              <IonCard>
                <IonCardHeader>
                  <IonImg
                    src={workers}
                    style={{ maxWidth: 80, maxHeight: 80, margin: "0 auto" }}
                  ></IonImg>

                  <IonCardTitle>עובדים באתר</IonCardTitle>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol
              className="ion-no-margin ion-no-padding"
              size="12"
              sizeXs="6"
              sizeSm="6"
              sizeMd="4"
              sizeLg="3"
              sizeXl="3"
            >
              <IonCard>
                <IonCardHeader>
                  <IonImg
                    src={orders}
                    style={{ maxWidth: 80, maxHeight: 80, margin: "0 auto" }}
                  ></IonImg>

                  <IonCardTitle>הזמנות האתר</IonCardTitle>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol
              className="ion-no-margin ion-no-padding"
              size="12"
              sizeXs="6"
              sizeSm="6"
              sizeMd="4"
              sizeLg="3"
              sizeXl="3"
            >
              <IonCard>
                <IonCardHeader>
                  <IonImg
                    src={steps}
                    style={{ maxWidth: 80, maxHeight: 80, margin: "0 auto" }}
                  ></IonImg>
                    <IonCardTitle>שלבי עבודה</IonCardTitle>
                </IonCardHeader>
              </IonCard>
            </IonCol>
            <IonCol
              className="ion-no-margin ion-no-padding"
              size="12"
              sizeXs="6"
              sizeSm="6"
              sizeMd="4"
              sizeLg="3"
              sizeXl="3"
            >
              <IonCard>
                <IonCardHeader>
                  <IonImg
                    src={calendar}
                    style={{ maxWidth: 80, maxHeight: 80, margin: "0 auto" }}
                  ></IonImg>
                    <IonCardTitle>פנקס האתר</IonCardTitle>
                </IonCardHeader>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default TabOperations;
