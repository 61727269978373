import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  personOutline,
  notificationsOutline,
  listOutline,
  homeOutline,
  chatbubbleOutline,
} from "ionicons/icons";
import TabProfile from "./pages/TabProfile";
import TabList from "./pages/TabOperations";
import TabChats from "./pages/TabChats";
import TabHome from "./pages/TabHome";
import TabNotifications from "./pages/TabNotifications";
import Login from "./pages/Login";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import TabSupport from "./pages/TabSupport";

const App: React.FC = () => (
  <IonApp dir="rtl">
    <IonReactRouter>
      <IonSplitPane contentId="main">
        <Menu />
        <IonRouterOutlet id="main">
          {/* <Switch><Route
              path="/"
              render={() => <Redirect to="/login" />}
              exact={true}
            />
            <Route path="/login" component={Login} exact={true} />
            <Route render={() => <Redirect to="/login" />} /> </Switch>*/}
          <Route
            path="/"
            render={() => <Redirect to="/login" />}
            exact={true}
          />

          <Route path="/login" component={Login} exact={true} />

          <IonTabs>
            <IonRouterOutlet>
              <Route
                path="/notifications"
                component={TabNotifications}
                exact={true}
              />
              <Route path="/home" component={TabHome} exact={true} />
              <Route path="/chats" component={TabChats} exact={true} />
              <Route path="/operations" component={TabList} exact={true} />
              <Route path="/profile" component={TabProfile} exact={true} />
              <Route path="/support" component={TabSupport} exact={true} />
            </IonRouterOutlet>

            <IonTabBar slot="bottom">
              <IonTabButton tab="tabProfile" href="/profile">
                <IonIcon icon={personOutline} />
                <IonLabel>החשבון שלי</IonLabel>
              </IonTabButton>
              <IonTabButton tab="tabNotifications" href="/notifications">
                <IonIcon icon={notificationsOutline} />
                <IonLabel>התראות</IonLabel>
              </IonTabButton>
              <IonTabButton tab="tabHome" href="/home">
                <IonIcon icon={homeOutline} />
                <IonLabel>דף הבית</IonLabel>
              </IonTabButton>
              <IonTabButton tab="tabOperations" href="/operations">
                <IonIcon icon={listOutline} />
                <IonLabel>פעולות</IonLabel>
              </IonTabButton>
              <IonTabButton tab="tabChats" href="/chats">
                <IonIcon icon={chatbubbleOutline} />
                <IonLabel>הודעות</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonRouterOutlet>
      </IonSplitPane>
    </IonReactRouter>
  </IonApp>
);

export default App;
