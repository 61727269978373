import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from "@ionic/react";import "./TabNotifications.css";
import { RefresherEventDetail } from "@ionic/core";
import { chevronDownCircleOutline } from "ionicons/icons";

function doRefresh(event: CustomEvent<RefresherEventDetail>) {
  console.log("Begin async operation");

  setTimeout(() => {
    console.log("Async operation has ended");
    event.detail.complete();
  }, 2000);
}

const TabNotifications: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <IonButtons slot="start">
            <IonMenuButton autoHide={false} />
          </IonButtons>
          <IonTitle>התראות</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher
          slot="fixed"
          onIonRefresh={doRefresh}
          pullFactor={0.5}
          pullMin={100}
          pullMax={200}
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonInfiniteScrollContent>
          <IonList>
            <IonItem>
              <IonLabel>
                <h2 className="ion-padding-bottom">
                  <b> אחמד מחאמיד מנהל </b>
                  הדרכות בטיחות
                </h2>
                <h6>10/2/2020 - שעה 2:10</h6>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h2 className="ion-padding-bottom">
                  <b> מוחמד מחאמיד מנהל </b>
                  ההזמנה הגיעה
                </h2>
                <h6>10/2/2020 - שעה 2:10</h6>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonInfiniteScrollContent>
      </IonContent>
    </IonPage>
  );
};

export default TabNotifications;
