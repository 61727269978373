import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonItemDivider,
  IonTextarea,
  IonButton,
  IonText,
  IonRouterLink,
  IonThumbnail,
  IonImg,
  IonListHeader,
  IonIcon,
  IonCard,
  IonMenuToggle,
} from "@ionic/react";
import { lockClosedOutline, mailOutline } from "ionicons/icons";
import Logo from "../assets/logo.png";
import "./Login.css";
import { Capacitor, Plugins, StatusBarStyle } from "@capacitor/core";

const { StatusBar } = Plugins;

const Login: React.FC = () => {
  const [text, setText] = useState<string>();
  const [pass, setPass] = useState<string>();

  StatusBar.setStyle({ style: StatusBarStyle.Dark });

  useEffect(() => {
    // code to run on component mount

    return () => {};
  }, []);
  return (
    <IonPage>
      <IonContent
        fullscreen
        className="background"
        no-bounce
        has-bouncing="false"
        forceOverscroll={false}
        scrollY={false}
      >
        <div className="container" style={{ maxWidth: 600 }}>
          <IonCard className="ion-padding ion-margin">
            <IonList className="ion-padding">
              <section
                className="ion-text-center ion-margin ion-padding-bottom"
                style={{
                  width: "90%",
                  maxWidth: 350,
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              >
                <IonImg src={Logo} />
              </section>

              <IonItem>
                <IonLabel position="floating">מייל</IonLabel>
                <IonInput
                  type="email"
                  name="email"
                  placeholder="כתובת מייל או מספר נייד "
                ></IonInput>
                <IonIcon icon={mailOutline} slot="start"></IonIcon>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">סיסמה</IonLabel>
                <IonInput
                  type="password"
                  name="password"
                  placeholder="תכניס סיסמה"
                ></IonInput>
                <IonIcon icon={lockClosedOutline} slot="start"></IonIcon>
              </IonItem>

              <section>
                <IonRouterLink
                  style={{ display: "block", textAlign: "right", fontSize: 14 }}
                  className=" ion-margin"
                  href="tabProfile"
                >
                  שכחתי סיסמה
                </IonRouterLink>
              </section>
              <section>
                <IonButton
                  href="home"
                  expand="block"
                  onClick={() => {
                    if (Capacitor.getPlatform() === "ios") {
                      //on login sucess change staus bar color
                      StatusBar.setStyle({ style: StatusBarStyle.Light });
                    }
                  }}
                >
                  התחבר
                </IonButton>
              </section>
            </IonList>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
