import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonAvatar,
  IonChip,
  IonItem,
  IonLabel,
  IonCard,
  IonButtons,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonList,
  IonItemDivider,
  IonCheckbox,
  IonRadio,
  IonToggle,
  IonInput,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonNote,
} from "@ionic/react";
import {
  archive,
  callOutline,
  createOutline,
  ellipsisHorizontal,
  ellipsisVertical,
  heart,
  mailOutline,
  pencilOutline,
  settingsOutline,
  star,
  trash,
} from "ionicons/icons";
import profileImage from "../assets/Untitled.png";
import "./TabProfile.css";

const TabProfile: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton autoHide={false} />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton>
              <IonIcon slot="icon-only" icon={settingsOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle style={{ textAlign: "center" }}>החשבון שלי</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-align-items-center">
        <IonAvatar
          class="ion-padding-top ion-margin-top ion-text-center"
          style={{ margin:"auto",minWidth: "180px", minHeight: "180px" }}
        >
          <img
            style={{ width: "150px", height: "150px" }}
            src={profileImage}
            alt="img"
          />
        </IonAvatar>
        <IonItem>
          <IonTitle size="large" class="ion-text-center">מחמוד מחאמיד</IonTitle>
        </IonItem>
        

        <IonItem>
          <IonIcon class="ion-padding" icon={mailOutline} />
          <IonLabel>
            <h2>מייל</h2>
            <h3>mhamod_hasan@outlook.com</h3>
          </IonLabel>
          <IonButton fill="clear">
            <IonIcon
              color="dark"
              size="default"
              slot="icon-only"
              icon={createOutline}
            />
          </IonButton>
        </IonItem>
        <IonItem>
          <IonIcon class="ion-padding" icon={callOutline} />
          <IonLabel>
            <h2>טלפון</h2>
            <h3>0505755810</h3>
          </IonLabel>
          <IonButton fill="clear">
            <IonIcon
              color="dark"
              size="default"
              slot="icon-only"
              icon={createOutline}
            />
          </IonButton>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default TabProfile;
