import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./TabChats.css";

const TabChats: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <IonButtons slot="start">
            <IonMenuButton autoHide={false} />
          </IonButtons>
          <IonTitle>הודעות</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      הודעות
      </IonContent>
    </IonPage>
  );
};

export default TabChats;
