import React, { useRef, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonItemDivider,
  IonTextarea,
  IonButton,
  IonText,
  IonRouterLink,
  IonMenuButton,
  IonButtons,
  IonSearchbar,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonImg,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonCardSubtitle,
  IonCardContent,
} from "@ionic/react";
import {
  cloud,
  cloudUpload,
  appsOutline,
  listCircleOutline,
  camera,
  bookmark,
  listOutline,
  mapOutline,
  locationOutline,
  gridOutline,
} from "ionicons/icons";
import {
  Plugins,
  CameraResultType,
  FilesystemDirectory,
  FilesystemEncoding,
} from "@capacitor/core";

import "./TabHome.css";
import "./Login.css";
import background from "../assets/background.jpg";

const { Camera, Filesystem } = Plugins;

const TabHome: React.FC = () => {
  const [text, setText] = useState<string>();
  const [pass, setPass] = useState<string>();
  const [imageSrc, setImagesrc] = useState<string>();
  const [searchText, setSearchText] = useState("");

  const txtField = React.useRef("uploadButton");

  const takePicture = async () => {
    /* const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
    });
    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    var imageUrl = image.webPath;
    // Can be set to the src of an image now
    setImagesrc(imageUrl); */
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton autoHide={false} />
          </IonButtons>
          <IonTitle>דף הבית</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/*    <IonSegment
          onIonChange={(e) => console.log("Segment selected", e.detail.value)}
        >
          <IonSegmentButton value="friends">
            <IonLabel>הצג כגלריה</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="enemies">
            <IonLabel> הצג כרשימה</IonLabel>
          </IonSegmentButton>
        </IonSegment> */}
        <IonSearchbar
          className="ion-padding-top ion-padding-bottom "
          style={{ borderBottom: "1px solid #f5f5f5" }}
          placeholder="שם הפעולה..."
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value!)}
        ></IonSearchbar>

        <IonGrid>
          <IonToolbar>
            <IonSegment
              onIonChange={(e) =>
                console.log("Segment selected", e.detail.value)
              }
            >
              <IonSegmentButton value="camera">
                <IonIcon icon={listOutline} />
              </IonSegmentButton>
              <IonSegmentButton value="bookmark">
                <IonIcon icon={gridOutline} />
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
          <IonRow>
            <IonCol
              className="ion-no-margin ion-no-padding"
              size="12"
              sizeXs="12"
              sizeSm="12"
              sizeMd="12"
              sizeLg="12"
              sizeXl="12"
            >
              <IonCard
                style={{ cursor: "pointer" }}
                onClick={() => {
                  alert("cleicked");
                }}
              >
                <IonCardHeader>
                  <IonCardTitle>חריש בית ספר - 209</IonCardTitle>
                </IonCardHeader>
                <IonCardContent className=" ion-no-margin">
                  בית ספר תיכון מס 209 התחיל ב 2020
                </IonCardContent>
                <IonRouterLink
                  className=" ion-padding-bottom ion-padding-start "
                  style={{ display: "block" }}
                >
                  <IonIcon icon={locationOutline} />
                  חריש
                </IonRouterLink>
                <IonButton className=" ion-margin-bottom ion-margin-start ">
                  הצג הכל
                </IonButton>
              </IonCard>
            </IonCol>
            <IonCol
              className="ion-no-margin ion-no-padding"
              size="12"
              sizeXs="12"
              sizeSm="12"
              sizeMd="12"
              sizeLg="12"
              sizeXl="12"
            >
              <IonCard
                style={{ cursor: "pointer" }}
                onClick={() => {
                  alert("cleicked");
                }}
              >
                <IonCardHeader>
                  <IonCardTitle>חריש בית ספר - 203</IonCardTitle>
                </IonCardHeader>
                <IonCardContent className=" ion-no-margin">
                  בית ספר תיכון מס 203 התחיל ב 2019
                </IonCardContent>
                <IonRouterLink
                  className=" ion-padding-bottom ion-padding-start "
                  style={{ display: "block" }}
                >
                  <IonIcon icon={locationOutline} />
                  חריש
                </IonRouterLink>
                <IonButton className=" ion-margin-bottom ion-margin-start ">
                  הצג הכל
                </IonButton>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        <input
          type="file"
          id="imageUpload"
          name="imageUpload"
          multiple={true}
          hidden={true}
        />
        <label className="file_input" htmlFor="imageUpload">
          העלאת תמונות
          <IonIcon slot="end" icon={cloudUpload} />
        </label>

        <img src={imageSrc} alt="" />
      </IonContent>
    </IonPage>
  );
};

export default TabHome;
